<template>
  <div class="body">
    <!-- 头部 -->
    <top :isOpen="isOpen"></top>
    <!-- 广告 -->
    <div class="banner_box">
      <div class="banner">
        <!-- <img :src="bannerImgSrc" alt="" /> -->
      </div>
    </div>
    <!-- 内容 -->
    <div class="content_box">
      <!-- 包裹内容的 -->
      <div class="contentorder">
        <div class="tabfastorder">
          <ul>
            <li
              v-for="(item, index) in btns"
              :key="index"
              :class="activeIndex == index ? 'btnAtiva' : ''"
              @click="handleToggle(index)"
            >
              <img src="" alt="" />{{ item }}
            </li>
          </ul>
        </div>
        <!-- 主体内容 -->
        <div class="container">
          <div v-show="activeIndex == 0">
            <!-- 内容描述 -->
            <div class="describe_box" style="height: 200px;">
              <!-- 题目 -->
              <div class="topic">
                <!-- <span>*</span> -->
                <p>内容描述</p>
                <p>:</p>
              </div>
              <div class="describe_content topica">
              <textarea
                    style="width: 100%;height: 200px;font-size: 14px;
                    border: 1px solid #d9d9d9;border-radius: 5px;
                    outline: none;resize: none;"
                  type="text"
                  v-model="input1"
                >
              </textarea>
              </div>
            </div>

            <!-- 下面的内容 -->
            <div class="bottom_content">
              <!-- 左部分 -->
              <div class="bottom_content_left">
                <!-- 上传文档 -->
                <div class="document">
                  <!-- 题目 -->
                  <div class="topic">
                    <span> </span>
                    <p>上传文档</p>
                    <p>:</p>
                  </div>
                  <!-- 右边 -->
                  <div class="document_content">
                    <el-upload
                      class="qaq"
                      action="/api/shopmini/wap_AddPdfDoc.html"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :on-success="ok"
                      :limit="1"
                      accept=".doc,.docx,.PDF,.pdf"
                    >
                      <i class="el-icon-plus"></i>
                      <img
                        width="100%"
                        src="../assets/customization/file_word.png"
                        alt=""
                        v-show="newUrlOne == '.docx' || newUrlOne == '.doc'"
                      />
                      <img
                        width="100%"
                        src="../assets/customization/pdf3.png"
                        alt=""
                        v-show="newUrlOne == '.pdf'"
                      />
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                      <!-- <img width="100%" src="../assets/customization/file_word.png" alt="" /> -->
                    </el-dialog>

                    <div class="document_content-b">
                      <p class="document_content-b-a">上传文档</p>
                      <p class="document_content-b-b">
                        (注意：仅支持：doc，docx,PDF)
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 姓名 -->
                <div class="name">
                  <!-- 题目 -->
                  <div class="topic">
                    <!-- <span>*</span> -->
                    <p>姓名</p>
                    <p>:</p>
                  </div>
                  <!-- 右边 -->
                  <div class="name_content">
                    <input
                      type="text"
                      placeholder="请输入您的真实姓名"
                      v-model="username1"
                    />
                  </div>
                </div>
                <!-- 手机号 -->
                <div class="cellphone">
                  <!-- 题目 -->
                  <div class="topic">
                    <span>*</span>
                    <p>手机号</p>
                    <p>:</p>
                  </div>
                  <div class="cellphone_content">
                    <input
                      type="tel"
                      name="text1"
                      maxlength="11"
                      v-model="iphone1"
                      placeholder="请输入您使用的手机号码（必填）"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    />
                    <p>
                      注意：请确认您的手机号码输入无误，我们的客服人员会与您联系！
                    </p>
                  </div>
                </div>
                <!-- 选择选项 -->
                <div class="xuan cellphone">
                    <div class="topic">
                      <span>*</span>
                      <p>定制分类</p>
                      <p>:</p>
                    </div>
                    <div class="cellphone_content cellphone_content_xuan">
                      <el-select v-model="value" placeholder="请选择" @change='selectChange'>
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                </div>
                <!-- 条款 -->
                <div class="clause">
                  <!-- 题目 -->
                  <div class="topic">
                    <span>*</span>
                    <p>定制条数</p>
                    <p>:</p>
                  </div>
                  <!-- 右 -->
                  <div class="clause_content">
                    <!-- <input type="number" placeholder="请输入定制条数（必填）" v-model="customNum1" /> -->
                    <input
                      type="tel"
                      name="text1"
                      maxlength="10"
                      v-model="customNum1"
                      placeholder="请输入定制条数（必填）"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    />
                  </div>
                </div>
                <!-- 单价 -->
                <div class="unit_price">
                  <!-- 题目 -->
                  <div class="topic">
                    <span></span>
                    <p>单价</p>
                    <p>:</p>
                  </div>
                  <!-- 右 -->
                  <div class="unit_price_content">￥{{ price }}</div>
                </div>
              </div>
              <!-- 右 -->
              <div class="bottom_content_right">
                <img src="../assets/customization/79.png" alt="" />
              </div>
            </div>
            <!-- 合计 -->
            <div class="total_box">
              <!-- 题目 -->
              <div class="topic">
                <span></span>
                <p>合计</p>
                <p>:</p>
              </div>
              <div class="total">￥{{ getInt(price, customNum1) }}</div>
            </div>
            <!-- 支付 -->
            <div class="pay_box">
              <div class="topic"></div>
              <div class="pay_content">
                <button @click="tijiao1">立即购买</button>
              </div>
            </div>
            <!-- 温馨提示 -->
            <div class="hint_content">
              <div class="hint_box">
                <div class="hint_text" v-html="content"></div>
              </div>
            </div>
          </div>
          <div v-show="activeIndex == 1">
            <!-- 声音录制  
                    @click="muica(index)"
            -->
            <div class="transcribe">
              <div class="transcribe_title">
                <h1>有声录制案例</h1>
                <div class="transcribe_title-box clearfix">
                  <div
                    class="voice"
                    v-for="(item, index) in caption"
                    :key="index"
                  >
                    <div class="voice_a">
                      <!-- 标题 -->
                      <span class="voice_a-a">{{ item.title }}</span>
                      <span class="voice_a-c" @click="volume"
                        ><img src="../assets/customization/bbb.png" alt=""
                      /></span>
                    </div>
                    <!-- 音频进度条 :theControlList="item.controlList"-->
                    <div class="voice_b">
                      <VueAudio ref="isClick" :theUrl="item.contentMp3[0]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="transcribe_bottom">
              <!-- 内容描述 -->
              <div class="describe_box">
                <!-- 题目 -->
                <div class="topic">
                  <!-- <span>*</span> -->
                  <p>内容描述</p>
                  <p>:</p>
                </div>
                <div class="describe_content">
                    <textarea
                        style="width: 100%;height: 200px;font-size: 14px;
                        border: 1px solid #d9d9d9;border-radius: 5px;
                        outline: none;resize: none;"
                    type="text"
                    v-model="input2"
                    >
                </textarea>
                </div>
              </div>
              <!-- 下面的内容 -->
              <div class="bottom_content">
                <!-- 左部分 -->
                <div class="bottom_content_left">
                  <!-- 上传文档 -->
                  <div class="document">
                    <!-- 题目 -->
                    <div class="topic">
                      <span> </span>
                      <p>上传文档</p>
                      <p>:</p>
                    </div>
                    <!-- 右边 -->
                    <div class="document_content">
                      <el-upload
                        class="qaq"
                        action="/api/shopmini/wap_AddPdfDoc.html"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-success="ok2"
                        :limit="1"
                        accept=".doc,.docx,.PDF,.pdf"
                      >
                        <i class="el-icon-plus"></i>
                        <img
                          width="100%"
                          src="../assets/customization/file_word.png"
                          alt=""
                          v-show="newUrlTow == '.docx' || newUrlTow == '.doc'"
                        />
                        <img
                          width="100%"
                          src="../assets/customization/pdf3.png"
                          alt=""
                          v-show="newUrlTow == '.pdf'"
                        />
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>

                      <div class="document_content-b">
                        <p class="document_content-b-a">上传文档</p>
                        <p class="document_content-b-b">
                          (注意：仅支持：doc，docx,PDF)
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- 姓名 -->
                  <div class="name">
                    <!-- 题目 -->
                    <div class="topic">
                      <!-- <span>*</span> -->
                      <p>姓名</p>
                      <p>:</p>
                    </div>
                    <!-- 右边 -->
                    <div class="name_content">
                      <input
                        type="text"
                        placeholder="请输入您的真实姓名"
                        v-model="username2"
                      />
                    </div>
                  </div>
                  <!-- 手机号 -->
                  <div class="cellphone">
                    <!-- 题目 -->
                    <div class="topic">
                      <span>*</span>
                      <p>手机号</p>
                      <p>:</p>
                    </div>
                    <div class="cellphone_content">
                      <!-- <input type="text" placeholder="请输入您使用的手机号码（必填）" v-model="iphone2" /> -->
                      <input
                        type="tel"
                        name="text1"
                        maxlength="11"
                        v-model="iphone2"
                        placeholder="请输入您使用的手机号码（必填）"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                      <p>
                        注意：请确认您的手机号码输入无误，我们的客服人员会与您联系！
                      </p>
                    </div>
                  </div>
                  <!-- 条款 -->
                  <div class="clause">
                    <!-- 题目 -->
                    <div class="topic">
                      <span>*</span>
                      <p>定制条数</p>
                      <p>:</p>
                    </div>
                    <!-- 右 -->
                    <div class="clause_content">
                      <!-- <input type="number" placeholder="请输入定制条数（必填）" v-model="customNum2" /> -->
                      <input
                        type="tel"
                        name="text1"
                        maxlength="10"
                        v-model="customNum2"
                        placeholder="请输入定制条数（必填）"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                    </div>
                  </div>
                  <!-- 单价 -->
                  <div class="unit_price">
                    <!-- 题目 -->
                    <div class="topic">
                      <span></span>
                      <p>单价</p>
                      <p>:</p>
                    </div>
                    <!-- 右 -->
                    <div class="unit_price_content">￥{{ priceaaa }}</div>
                  </div>
                </div>
                <!-- 右 -->
                <div class="bottom_content_right">
                  <img src="../assets/customization/79.png" alt="" />
                </div>
              </div>
              <!-- 合计 -->
              <div class="total_box">
                <!-- 题目 -->
                <div class="topic">
                  <span></span>
                  <p>合计</p>
                  <p>:</p>
                  <!-- <p>:</p> -->
                </div>
                <div class="total">￥{{ getInt(priceaaa, customNum2) }}</div>
              </div>
              <!-- 支付 -->
              <div class="pay_box">
                <div class="topic"></div>
                <div class="pay_content">
                  <button @click="tijiao2">立即购买</button>
                </div>
              </div>

              <!-- 声音录制温馨提示 -->
              <div class="hint_content">
                <div class="hint_box">
                  <div class="hint_text" v-html="contentAudio"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeIndex == 2">
            <!-- 视频案例 -->
            <div class="video_box">
              <div class="video_carousel_title">
                <h1>视频案例</h1>
              </div>
              <!-- 视频轮播   -->
              <div>
                <!-- 播放按钮加视频 -->
                <div class="shiping">
                  <div class="video">
                    <video
                      width="830"
                      height="480"
                      :src="srcChange"
                      controls="controls"
                      disablePictureInPicture
                      controlsList="nodownload"
                    ></video>
                  </div>
                  <ul class="video-a">
                    <li
                      v-for="(item, index) in listData"
                      :key="index"
                      :style="
                        'background-image:url(' +
                        item.img +
                        ');background-size:cover;no-repeat'
                      "
                    >
                      <div v-show="item.state" class="being">
                        <span>正在播放</span>
                        <img src="../../src/assets/video/paly.png" alt="" />
                      </div>
                      <div v-show="item.stateEl">
                        <p
                          class="btn"
                          @click="changeSrc(item.src, index, item)"
                        >
                          点击播放
                        </p>
                        <p class="rise">{{ item.price }}元套餐</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- 价格详情 -->
                <div class="video-b">
                  <p class="video-b_a">{{ string }}元套餐</p>
                  <p class="video-b_b">
                    {{ videoText }}
                  </p>
                  <!-- <p class="video-b_c">
                    内容特点:以商品展示镜头为主,配合真人口播，真人出镜(不露脸)，含背景音乐，后期制作，支持超清画质。价格亲民，效果卓越。
                  </p> -->
                </div>
              </div>
            </div>
            <div class="video_bottom">
              <!-- 内容描述 -->
              <div class="describe_box">
                <!-- 题目 -->
                <div class="topic">
                  <!-- <span>*</span> -->
                  <p>内容描述</p>
                  <p>:</p>
                </div>
                <div class="describe_content">
                    <textarea
                        style="width: 100%;height: 200px;font-size: 14px;
                        border: 1px solid #d9d9d9;border-radius: 5px;
                        outline: none;resize: none;"
                        type="text"
                        v-model="input3">
                    </textarea>
                </div>
              </div>
              <!-- 下面的内容 -->
              <div class="bottom_content">
                <!-- 左部分 -->
                <div class="bottom_content_left">
                  <!-- 上传文档 -->
                  <div class="document">
                    <!-- 题目 -->
                    <div class="topic">
                      <span> </span>
                      <p>上传文档</p>
                      <p>:</p>
                    </div>
                    <!-- 右边 -->
                    <div class="document_content">
                      <el-upload
                        class="qaq"
                        action="/api/shopmini/wap_AddPdfDoc.html"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-success="ok3"
                        :limit="1"
                        accept=".doc,.docx,.PDF,.pdf"
                      >
                        <i class="el-icon-plus"></i>
                        <img
                          width="100%"
                          src="../assets/customization/file_word.png"
                          alt=""
                          v-show="
                            newUrlThree == '.docx' || newUrlThree == '.doc'
                          "
                        />
                        <img
                          width="100%"
                          src="../assets/customization/pdf3.png"
                          alt=""
                          v-show="newUrlThree == '.pdf'"
                        />
                      </el-upload>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>
                      <div class="document_content-b">
                        <p class="document_content-b-a">上传文档</p>
                        <p class="document_content-b-b">
                          (注意：仅支持：doc,docx,PDF)
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- 姓名 -->
                  <div class="name">
                    <!-- 题目 -->
                    <div class="topic">
                      <!-- <span>*</span> -->
                      <p>姓名</p>
                      <p>:</p>
                    </div>
                    <!-- 右边 -->
                    <div class="name_content">
                      <input
                        type="text"
                        placeholder="请输入您的真实姓名"
                        v-model="username3"
                      />
                    </div>
                  </div>
                  <!-- 手机号 -->
                  <div class="cellphone">
                    <!-- 题目 -->
                    <div class="topic">
                      <span>*</span>
                      <p>手机号</p>
                      <p>:</p>
                    </div>
                    <div class="cellphone_content">
                      <!-- <input type="text" placeholder="请输入您使用的手机号码（必填）" v-model="iphone3" /> -->
                      <input
                        type="tel"
                        name="text1"
                        maxlength="11"
                        v-model="iphone3"
                        placeholder="请输入您使用的手机号码（必填）"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                      <p>
                        注意：请确认您的手机号码输入无误，我们的客服人员会与您联系！
                      </p>
                    </div>
                  </div>
                  <!-- 条款 -->
                  <div class="clause">
                    <!-- 题目 -->
                    <div class="topic">
                      <span>*</span>
                      <p>定制条数</p>
                      <p>:</p>
                    </div>
                    <!-- 右 -->
                    <div class="clause_content">
                      <!-- <input type="number" placeholder="请输入定制条数（必填）" v-model="customNum3" /> -->
                      <input
                        type="tel"
                        name="text1"
                        maxlength="10"
                        v-model="customNum3"
                        placeholder="请输入定制条数（必填）"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      />
                    </div>
                  </div>
                  <!-- 单价 -->
                  <div class="unit_price">
                    <!-- 题目 -->
                    <div class="topic">
                      <span></span>
                      <p>单价</p>
                      <p>:</p>
                    </div>
                    <!-- 右 -->
                    <div class="unit_price_content">￥{{ string }}</div>
                  </div>
                </div>
                <!-- 右 -->
                <div class="bottom_content_right">
                  <img src="../assets/customization/79.png" alt="" />
                </div>
              </div>
              <!-- 合计 -->
              <div class="total_box">
                <!-- 题目 -->
                <div class="topic">
                  <span></span>
                  <p>合计</p>
                  <p>:</p>
                  <!-- <p>:</p> -->
                </div>
                <div class="total">￥{{ getInt(string, customNum3) }}</div>
              </div>
              <!-- 支付 -->
              <div class="pay_box">
                <div class="topic"></div>
                <div class="pay_content">
                  <!-- <button @click="goumai3">立即购买</button> -->
                  <button @click="tijiao3">立即购买</button>
                </div>
              </div>
              <!-- 视频录制温馨提示 -->
              <div class="hint_content">
                <div class="hint_box">
                  <div class="hint_text" v-html="contentVideo"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeIndex == 3">
            <!-- 内容描述 -->
            <div class="describe_box" style="height: 200px;">
              <!-- 题目 -->
              <div class="topic">
                <!-- <span>*</span> -->
                <p>内容描述</p>
                <p>:</p>
              </div>
              <div class="describe_content topica">
                <textarea
                    style="width: 100%;height: 200px;font-size: 14px;
                    border: 1px solid #d9d9d9;border-radius: 5px;
                    outline: none;resize: none;"
                  type="text"
                  v-model="input4">
              </textarea>
              </div>
            </div>

            <!-- 下面的内容 -->
            <div class="bottom_content">
              <!-- 左部分 -->
              <div class="bottom_content_left">
                <!-- 上传文档 -->
                <div class="document">
                  <!-- 题目 -->
                  <div class="topic">
                    <span> </span>
                    <p>上传文档</p>
                    <p>:</p>
                  </div>
                  <!-- 右边 -->
                  <div class="document_content">
                    <el-upload
                      class="qaq"
                      action="/api/shopmini/wap_AddPdfDoc.html"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :on-success="ok4"
                      :limit="1"
                      accept=".doc,.docx,.PDF,.pdf"
                    >
                      <i class="el-icon-plus"></i>
                      <img
                        width="100%"
                        src="../assets/customization/file_word.png"
                        alt=""
                        v-show="tmealThree == '.docx' || tmealThree == '.doc'"
                      />
                      <img
                        width="100%"
                        src="../assets/customization/pdf3.png"
                        alt=""
                        v-show="tmealThree == '.pdf'"
                      />
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                      <!-- <img width="100%" src="../assets/customization/file_word.png" alt="" /> -->
                    </el-dialog>

                    <div class="document_content-b">
                      <p class="document_content-b-a">上传文档</p>
                      <p class="document_content-b-b">
                        (注意：仅支持：doc，docx,PDF)
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 姓名 -->
                <div class="name">
                  <!-- 题目 -->
                  <div class="topic">
                    <!-- <span>*</span> -->
                    <p>姓名</p>
                    <p>:</p>
                  </div>
                  <!-- 右边 -->
                  <div class="name_content">
                    <input
                      type="text"
                      placeholder="请输入您的真实姓名"
                      v-model="username4"
                    />
                  </div>
                </div>
                <!-- 手机号 -->
                <div class="cellphone">
                  <!-- 题目 -->
                  <div class="topic">
                    <span>*</span>
                    <p>手机号</p>
                    <p>:</p>
                  </div>
                  <div class="cellphone_content">
                    <input
                      type="tel"
                      name="text1"
                      maxlength="11"
                      v-model="iphone4"
                      placeholder="请输入您使用的手机号码（必填）"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    />
                    <p>
                      注意：请确认您的手机号码输入无误，我们的客服人员会与您联系！
                    </p>
                  </div>
                </div>
                <!-- 选择选项 -->
                <div class="xuan cellphone">
                    <div class="topic">
                      <span>*</span>
                      <p>套餐分类</p>
                      <p>:</p>
                    </div>
                    <div class="cellphone_content cellphone_content_xuan">
                      <el-select v-model="value1" placeholder="请选择" @change='tmealChange'>
                        <el-option
                          v-for="item in optionsTmeal"
                          :key="item.value1"
                          :label="item.label"
                          :value="item.value1">
                        </el-option>
                      </el-select>
                    </div>
                </div>
                <!-- 条款 -->
                <div class="clause">
                  <!-- 题目 -->
                  <div class="topic">
                    <span>*</span>
                    <p>定制月数</p>
                    <p>:</p>
                  </div>
                  <!-- 右 -->
                  <div class="clause_content">
                    <!-- <input type="number" placeholder="请输入定制条数（必填）" v-model="customNum1" /> -->
                    <input
                      type="tel"
                      name="text1"
                      maxlength="10"
                      v-model="customNum4"
                      placeholder="请输入定制月数（必填）"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    />
                  </div>
                </div>
                <!-- 单价 -->
                <div class="unit_price">
                  <!-- 题目 -->
                  <div class="topic">
                    <span></span>
                    <p>单价</p>
                    <p>:</p>
                  </div>
                  <!-- 右 -->
                  <div class="unit_price_content">￥{{ priceTmeal }}</div>
                </div>
              </div>
              <!-- 右 -->
              <div class="bottom_content_right">
                <img src="../assets/customization/79.png" alt="" />
              </div>
            </div>
            <!-- 合计 -->
            <div class="total_box">
              <!-- 题目 -->
              <div class="topic">
                <span></span>
                <p>合计</p>
                <p>:</p>
              </div>
              <div class="total">￥{{ getInt(priceTmeal, customNum4) }}</div>
            </div>
            <!-- 支付 -->
            <div class="pay_box">
              <div class="topic"></div>
              <div class="pay_content">
                <button @click="tijiao4">立即购买</button>
              </div>
            </div>
            <!-- 温馨提示 -->
            <div class="hint_content">
              <div class="hint_box">
                <div class="hint_text" v-html="contentTmeal"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 广告框 -->
    <div class="advertising_boxinfo">
      <div class="advertisingstr">
        <!-- <img :src="bannerImgSrc" alt="" /> -->
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <!-- 底部 -->
    <bottom class="o"></bottom>
  </div>
</template>
<script>
    import VueAudio from "../vueAudio/vueAudio";
    import Qs from "qs";
    import {
        getCustmizationImg,
        getCustmizationplay,
        getCustmizationsong,
        getCustmizationvideo,
        getCustomizedClass,
        geTmeal,
        getCustmizationDOC,
        addContent,
    } from "../http";
    export default {
        data() {
            return {
                options: [],
                optionsTmeal: [],
                value: '',
                priceTmeal: 0, //套餐价格
                contentTmeal: '', //套餐提示
                value1: '', //套餐选中的

                isOpen: 0,

                // ---------------------------
                btns: ["剧本定制", "有声录制", "短视频制作", "精品剧本套餐"],
                activeIndex: 0,
                dialogImageUrl: "",
                dialogVisible: false,
                msg: 0,
                shop: true,
                shopa: true,
                shopb: true,
                qaq: false,
                qaqa: false,
                qaqb: false,

                bannerImgSrc: "",
                price: "",
                content: [],
                caption: [],
                music: [],
                call: [],
                msg: "默认",
                listData: [],
                string: "",
                srcChange: "",
                input1: '',
                username1: "",
                iphone1: "",
                customNum1: "",
                url1: "",
                input2: "",
                username2: "",
                iphone2: "",
                customNum2: "",
                url2: "",
                input3: "",
                username3: "",
                iphone3: "",
                customNum3: "",
                url3: "",

                input4: "",
                username4: "",
                iphone4: "",
                customNum4: "",
                url4: "",

                valuea: [],
                dingdan: "",
                priceaaa: "",
                festivala: "",
                festivalb: "",
                festivalc: "",
                activeaIndex: 0,
                newUrlOne: "", //短剧本
                newUrlTow: "", //有声录制
                newUrlThree: "", //短视频制作
                tmealThree: "", //短视频制作
                tmeal: '', //套餐
                isShow: true, //播放按钮
                isNum: 0,
                indexSrc: "",
                videoText: "",
                contentVideo: "", //短剧本温馨提示
                contentAudio: "", //声音录制温馨提示
            };
        },
        // /页面初始化
        created() {
            this.getData();
            //从sessionStorage中获取用户的类型
            var infostr = sessionStorage.getItem("key");
            var keyaaa = JSON.parse(infostr);
            this.valuea = keyaaa;
        },
        mounted() {
            this.getBannerImg();
            this.getplay();
            this.getplaysong();
            this.getplayClass();
            this.getplayvideo();
            this.getsong();
            this.getClass();
            this.video();
            this.addpdf();
            //剧本套餐
            this.getScriptTmeal()
            this.$axios
                .get("/api/shopmini/wap_CustomizationMp4.html", {})
                .then((res) => {
                    this.changeSrc(res.data.data[0].contentMp4, 0);
                });
        },
        components: {
            VueAudio,
        },
        methods: {
            async getData() {
                let data = [{
                    name: "视频1",
                    src: [],
                    state: false,
                    stateEl: true,
                    msgs: "499",
                    price: "1",
                    videoText: "",
                }, {
                    name: "视频2",
                    src: [],
                    state: false,
                    stateEl: true,
                    msgs: "1299",
                    price: "2",
                    videoText: "",
                }, {
                    name: "视频3",
                    src: [],
                    state: false,
                    stateEl: true,
                    msgs: "1899",
                    price: "3",
                    videoText: "",
                }, ];
                this.listData = await data;
                this.msg = this.listData[0].src;
                this.string = this.listData[0].msgs;
            },
            changeSrc(src, index, item) {
                this.srcChange = src[0];
                this.msg = src;
                this.listData[index].state = true;
                this.listData[index].stateEl = false;
                for (let i = 0; i < this.listData.length; i++) {
                    if (i !== index) {
                        this.listData[i].state = false;
                        this.listData[i].stateEl = true;
                    }
                }
                this.string = this.listData[index].msgs;
                this.videoText = this.listData[index].videoText;
            },
            // 取整方法
            getInt(num, money) {
                return (num * money).toFixed(2);
            },
            //获得banner图
            async getBannerImg() {
                let imgDataSrc = await getCustmizationImg();
                this.bannerImgSrc = imgDataSrc;
            },
            //剧本定制
            async getplay() {
                let script = await getCustmizationplay();
                this.content = script.defaulttion;
                var reg = new RegExp("@", "g");
                this.input1 = script.defaulttion.replace(reg, `
  `);
            },
            //有声录制
            async getplaysong() {
                let script = await getCustmizationsong();
                this.content = script.defaulttion;
                var reg = new RegExp("@", "g");
                this.input2 = script.defaulttion.replace(reg, `
  `);
            },
            //短视频制作
            async getplayvideo() {
                let script = await getCustmizationvideo();
                this.content = script.defaulttion;
                var reg = new RegExp("@", "g");
                this.input3 = script.defaulttion.replace(reg, `
  `);
            },
            //精品剧本套餐
            async getplayClass() {
                let script = await getCustomizedClass();
                this.content = script.defaulttion;
                var reg = new RegExp("@", "g");
                this.input4 = script.defaulttion.replace(reg, `
  `);
            },


            // 获取定制分类
            async getClass() {
                let script = await getCustomizedClass();
                this.content = script.data[0].content;
                this.options = script.data.map((item, index) => {
                    return {
                        value: index,
                        label: item.title,
                        price: item.price
                    }
                });
                this.value = 0;
                this.price = script.data[0].price;
            },
            //剧本套餐
            async getScriptTmeal() {
                let script = await geTmeal();
                this.contentTmeal = script.data[0].content;
                this.optionsTmeal = script.data.map((item, index) => {
                    return {
                        value1: index,
                        label: item.title,
                        price: item.price
                    }
                });
                this.value1 = 0;
                this.priceTmeal = script.data[0].price;
                // this.tmeal = script.data.content;
            },
            // 定制服务分类变化
            selectChange() {
                console.log(this.optionsTmeal)
                this.price = this.options[this.value].price;
            },
            // 套餐分类
            tmealChange() {
                console.log(this.value)
                this.priceTmeal = this.optionsTmeal[this.value1].price;
            },
            //有声录制
            async getsong() {
                let song = await getCustmizationsong();
                this.caption = song.data;
                this.priceaaa = song.datas[0].price;
                this.contentAudio = song.datas[0].content;
                // this.festivala = this.caption[0].price;
                // this.festivalb = this.caption[1].price;
                // this.festivalc = this.caption[2].price;
            },
            // muica(index) {
            //
            //   this.activeaIndex = index;
            //   if (this.activeaIndex == 0) {
            //     this.priceaaa = this.festivala;
            //   } else if (this.activeaIndex == 1) {
            //     this.priceaaa = this.festivalb;
            //   } else if (this.activeaIndex == 2) {
            //     this.priceaaa = this.festivalc;
            //   } else {
            //   }
            // },
            //短视频
            async video() {
                let mas = await getCustmizationvideo();
                this.getData = mas.data;
                this.contentVideo = mas.datas[0].content;
                this.indexSrc = mas.data[0].contentMp4[0];
                for (let i = 0; i < this.getData.length; i++) {
                    this.getData[i].state = false;
                    this.getData[i].stateEl = true;
                }
                this.music = mas.data;
                this.listData[0].price = this.music[0].price;
                this.listData[1].price = this.music[1].price;
                this.listData[2].price = this.music[2].price;

                this.listData[0].videoText = this.music[0].secondtitle;
                this.listData[1].videoText = this.music[1].secondtitle;
                this.listData[2].videoText = this.music[2].secondtitle;

                this.listData[0].msgs = this.music[0].price;
                this.listData[1].msgs = this.music[1].price;
                this.listData[2].msgs = this.music[2].price;
                this.listData[0].img = this.music[0].img;
                this.listData[1].img = this.music[1].img;
                this.listData[2].img = this.music[2].img;
                this.listData[0].src = this.music[0].contentMp4;
                this.listData[1].src = this.music[1].contentMp4;
                this.listData[2].src = this.music[2].contentMp4;
            },
            //上传文件
            async addpdf(file) {
                // let Doc = await getCustmizationDOC(file);
            },
            // 音量按钮
            volume() {},
            handleToggle(index) {
                this.activeIndex = index;
            },
            // 上传文件
            handleRemove(file, fileList) {},
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            translate(url) {
                let inIndex = url.lastIndexOf(".");
                let newUrl = url.substring(inIndex);
                return newUrl;
            },
            // 触发上传文件
            ok(res, file) {
                // ;
                // 发送 POST 请求
                var form = new FormData(); // 可以增加表单数据
                form.append("file", file.raw); // 文件对象
                // XMLHttpRequest 对象
                var xhr = new XMLHttpRequest();
                xhr.open("post", this.http + "/shopmini/wap_AddPdfDoc.html", true);
                xhr.onload = (res) => {
                    var dat = res.target.response;
                    dat = JSON.parse(dat);
                    this.url1 = dat.url;
                    this.newUrlOne = this.translate(dat.url);
                };
                xhr.send(form);
            },
            // 触发上传文件
            ok2(res, file) {
                var form = new FormData(); // 可以增加表单数据
                form.append("file", file.raw); // 文件对象
                // XMLHttpRequest 对象
                var xhr = new XMLHttpRequest();
                xhr.open("post", this.http + "/shopmini/wap_AddPdfDoc.html", true);
                xhr.onload = (res) => {
                    var dat = res.target.response;
                    dat = JSON.parse(dat);
                    this.url2 = dat.url;
                    this.newUrlTow = this.translate(dat.url);
                };
                xhr.send(form);
            },
            // 触发上传文件
            ok3(res, file) {
                // ;
                // 发送 POST 请求
                var form = new FormData(); // 可以增加表单数据
                form.append("file", file.raw); // 文件对象
                // XMLHttpRequest 对象
                var xhr = new XMLHttpRequest();
                xhr.open("post", this.http + "/shopmini/wap_AddPdfDoc.html", true);
                xhr.onload = (res) => {
                    var dat = res.target.response;
                    dat = JSON.parse(dat);
                    this.url3 = dat.url;
                    this.newUrlThree = this.translate(dat.url);
                };
                xhr.send(form);
            },
            // 触发上传文件
            ok4(res, file) {
                // ;
                // 发送 POST 请求
                var form = new FormData(); // 可以增加表单数据
                form.append("file", file.raw); // 文件对象
                // XMLHttpRequest 对象
                var xhr = new XMLHttpRequest();
                xhr.open("post", this.http + "/shopmini/wap_AddPdfDoc.html", true);
                xhr.onload = (res) => {
                    var dat = res.target.response;
                    dat = JSON.parse(dat);
                    this.url4 = dat.url;
                    this.tmealThree = this.translate(dat.url);
                };
                xhr.send(form);
            },
            async tijiao1() {
                let that = this;
                // 判断是否登录
                if (
                    that.valuea == null ||
                    that.valuea.uid == "" ||
                    that.valuea.phone == ""
                ) {
                    that.$message({
                        message: "请先登录",
                        type: "warning",
                        duration: 2000,
                    });
                    this.isOpen = 1
                    setTimeout(()=>{
                      this.isOpen = 0
                    }, 200)
                    return;
                }
                // 内容描述
                let content = this.input1;
                // 姓名
                let username = this.username1;
                // 手机号
                let pthon = this.iphone1;
                // 定制条数
                let num = this.customNum1;
                if (pthon == "" || num == "") {
                    that.$message({
                        message: "请正确输入必填内容",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.$axios({
                        method: "post",
                        url: "/api/payment/pcwxpayment_Dznative.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            uid: that.valuea.uid,
                            url: that.url1,
                            content,
                            username,
                            pthon,
                            num,
                            money: that.price,
                            title: that.options[that.value].label,
                            type: "剧本定制",
                            types: 1,
                        }),
                    })
                    .then(function(res) {
                        that.$message("正在跳转购买页");
                        that.$router.push({
                            name: "Pay",
                            params: {
                                // num: that.customNum1,
                                price: that.price * that.customNum1,
                                ordernumber: res.data.dingdan,
                                pricd: res.data.url,
                                title: "剧本定制",
                            },
                        });
                    })
                    .catch(function(error) {});
            },

            async tijiao2() {
                let that = this;
                // 判断是否登录
                if (
                    that.valuea == null ||
                    that.valuea.uid == "" ||
                    that.valuea.phone == ""
                ) {
                    that.$message({
                        message: "请先登录",
                        type: "warning",
                        duration: 2000,
                    });
                    
                    this.isOpen = 1
                    setTimeout(()=>{
                      this.isOpen = 0
                    }, 200)
                    return;
                }
                // 内容描述
                let content = this.input2;
                // 姓名
                let username = this.username2;
                // 手机号
                let pthon = this.iphone2;
                // 定制条数
                let num = this.customNum2;
                if (pthon == "" || num == "") {
                    that.$message({
                        message: "请正确输入必填内容",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.$axios({
                        method: "post",
                        url: "/api/payment/pcwxpayment_Dznative.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            uid: that.valuea.uid,
                            url: that.url2,
                            content,
                            username,
                            pthon,
                            num,
                            money: that.priceaaa,
                            type: "有声录制",
                            types: 2,
                        }),
                    })
                    .then(function(res) {
                        that.$message("正在跳转购买页");
                        that.$router.push({
                            name: "Pay",
                            params: {
                                price: that.priceaaa * that.customNum2,
                                ordernumber: res.data.dingdan,
                                pricd: res.data.url,
                                title: "有声录制",
                            },
                        });
                    })
                    .catch(function(error) {});
            },
            async tijiao3() {
                let that = this;
                // 判断是否登录
                if (
                    that.valuea == null ||
                    that.valuea.uid == "" ||
                    that.valuea.phone == ""
                ) {
                    that.$message({
                        message: "请先登录",
                        type: "warning",
                        duration: 2000,
                    });
                    
                    this.isOpen = 1
                    setTimeout(()=>{
                      this.isOpen = 0
                    }, 200)
                    return;
                }
                // 内容描述
                let content = this.input3;
                // 姓名
                let username = this.username3;
                // 手机号
                let pthon = this.iphone3;
                // 定制条数
                let num = this.customNum3;

                if (pthon == "" || num == "") {
                    that.$message({
                        message: "请正确输入必填内容",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.$axios({
                        method: "post",
                        url: "/api/payment/pcwxpayment_Dznative.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            uid: that.valuea.uid,
                            url: that.url3,
                            content,
                            username,
                            pthon,
                            num,
                            money: that.string,
                            type: "短视频制作",
                            types: 3,
                        }),
                    })
                    .then(function(res) {
                        that.$message("正在跳转购买页");
                        that.$router.push({
                            name: "Pay",
                            params: {
                                price: that.string * that.customNum3,
                                ordernumber: res.data.dingdan,
                                pricd: res.data.url,
                                title: "短视频制作",
                            },
                        });
                    })
                    .catch(function(error) {});
            },
            async tijiao4() {
                let that = this;
                // 判断是否登录
                if (
                    that.valuea == null ||
                    that.valuea.uid == "" ||
                    that.valuea.phone == ""
                ) {
                    that.$message({
                        message: "请先登录",
                        type: "warning",
                        duration: 2000,
                    });
                    
                    this.isOpen = 1
                    setTimeout(()=>{
                      this.isOpen = 0
                    }, 200)
                    return;
                }
                // 内容描述
                let content = this.input4;
                // 姓名
                let username = this.username4;
                // 手机号
                let pthon = this.iphone4;
                // 定制条数
                let num = this.customNum4;

                if (pthon == "" || num == "") {
                    that.$message({
                        message: "请正确输入必填内容",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.$axios({
                        method: "post",
                        url: "/api/payment/pcwxpayment_Dznative.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            uid: that.valuea.uid,
                            url: that.url4,
                            content,
                            username,
                            pthon,
                            num,
                            money: that.priceTmeal,
                            title: that.optionsTmeal[that.value1].label,
                            type: "精品剧本套餐 ",
                            types: 4,
                        }),
                    })
                    .then(function(res) {
                        that.$message("正在跳转购买页");
                        that.$router.push({
                            name: "Pay",
                            params: {
                                price: that.priceTmeal * that.customNum4,
                                ordernumber: res.data.dingdan,
                                pricd: res.data.url,
                                title: "精品剧本套餐 ",
                            },
                        });
                    })
                    .catch(function(error) {});
            },
        },
    };
</script>

<style scoped>
    .el-upload--picture-card {
        width: 110px;
        height: 110px;
        line-height: 110px;
        border: 1px solid rgb(206, 196, 196);
    }
    
    .el-upload-list--picture-card .el-upload-list__item {
        width: 110px !important;
        height: 110px !important;
    }
    
    .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        width: 60%;
        height: 60%;
        margin: 20%;
    }
</style>
<style scoped>
    /* 页面 */
    
    .body {
        width: 100%;
        min-height: 100vh;
        background-color: #fafafa;
    }
    /* 广告 */
    
    .banner_box {
        margin-top: 70px;
        width: 100%;
        min-width: 1200px;
    }
    
    .banner {
        width: 1200px;
        margin: auto;
    }
    
    .banner img {
        width: 1200px;
        margin: auto;
    }
    /* 内容 */
    
    .content_box {
        width: 100%;
        min-width: 1200px;
    }
    /* 包裹内容的盒子 */
    
    .contentorder {
        width: 1200px;
        margin: auto;
        background-color: white;
        padding-top: 40px;
    }
    /* 选项卡 */
    
    .tabfastorder {
        width: 50%;
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
        border: 1px #e6e6e6 solid;
    }
    
    .tabfastorder ul {
        display: flex;
        justify-content: center;
    }
    
    .tabfastorder ul li {
        width: 200px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        border-left: 1px #e6e6e6 solid;
        color: #333333;
    }
    
    .btnAtiva {
        /* background: red; */
        background-color: #f62959;
        color: white !important;
        border: 1px transparency solid;
    }
    /* 内容 */
    
    .container>div:nth-of-type(1),
    .container>div:nth-of-type(4) {
        width: 90%;
        margin: auto;
        /* background: #ccc; */
        margin-top: 40px;
        padding-bottom: 30px;
    }
    
    .container>div:nth-of-type(2),
    .container>div:nth-of-type(3),
    .container>div:nth-of-type(4) {
        margin-top: 40px;
        padding-bottom: 30px;
    }
    /* 内容描述 */
    
    .topic {
        width: 100px;
        height: 50px;
        line-height: 46px;
        display: flex;
        /* background-color: red; */
    }
    /* .topica {
        margin-left: -50px;
    } */
    
    .topic p:nth-of-type(1) {
        width: 80%;
        text-align: justify;
        text-align-last: justify;
        color: #333333;
    }
    
    .topic span {
        display: block;
        width: 14px;
        text-align: center;
        color: #f62959;
    }
    
    .describe_content {
        width: 89%;
        display: flex;
        align-items: center;
        margin-left: 1%;
        height: 200px;
    }
    
    .describe_content .textarea {
        width: 100%;
        height: 200px;
        font-size: 14px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        resize: none;
        outline: none;
    }
    
    .describe_content input {
        width: 100%;
        height: 44px;
        font-size: 14px;
        text-indent: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }
    
    .describe_content input::-webkit-input-placeholder {
        color: 14px;
    }
    
    .describe_box {
        width: 100%;
        height: 200px;
        display: flex;
        /* margin-left: -12px; */
    }
    /* 下面内容 */
    
    .bottom_content {
        width: 100%;
        display: flex;
        border-bottom: 2px #e5e5e5 dashed;
        margin-top: 30px;
    }
    
    .bottom_content>div {
        width: 50%;
        /* border: 1px #000 solid; */
    }
    /* 上传文档 */
    
    .document {
        width: 100%;
        height: 120px;
        display: flex;
    }
    /* 上传文档右边框 */
    
    .document_content {
        width: 80%;
        display: flex;
        align-items: center;
        margin-left: 2%;
    }
    /* element ui样式修改 */
    
    .qaq {
        display: flex;
        position: relative;
        top: 0;
        left: 0;
    }
    
    .qaq img {
        position: absolute;
        top: 0;
        left: 0;
        width: 146px;
        height: 146px;
    }
    /* 注意 */
    
    .document_content-b {
        width: 45%;
        height: 100%;
        margin-left: 5%;
    }
    /* 文档文字 */
    
    .document_content-b-a {
        width: 70px;
        height: 30px;
        color: rgb(246, 41, 89);
        border: 1px solid rgb(246, 41, 89);
        border-radius: 7px;
        padding-left: 10%;
        line-height: 30px;
        margin-top: 8%;
        font-size: 13px;
    }
    
    .document_content-b-b {
        width: 100%;
        height: 20%;
        margin-top: 5%;
        font-size: 12px;
        color: gray;
    }
    /* 姓名 */
    
    .name {
        width: 100%;
        height: 50px;
        display: flex;
        margin-top: 30px;
    }
    
    .name_content {
        width: 82%;
        display: flex;
        align-items: center;
        margin-left: 2%;
    }
    
    .name_content input {
        width: 338px;
        height: 44px;
        font-size: 14px;
        text-indent: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }
    
    .name_content input::-webkit-input-placeholder {
        color: 14px;
    }
    /* 手机号 */
    
    .cellphone {
        width: 100%;
        height: 70px;
        display: flex;
        margin-top: 30px;
    }
    
    .cellphone_content {
        width: 82%;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
    }
    
    .cellphone_content p {
        font-size: 12px;
        color: #ff5c0d;
        line-height: 30px;
        text-indent: 20px;
    }
    
    .cellphone_content input {
        width: 338px;
        height: 44px;
        font-size: 14px;
        text-indent: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }
    /* 条款 */
    
    .clause {
        width: 100%;
        height: 50px;
        display: flex;
        margin-top: 30px;
    }
    
    .clause_content {
        width: 82%;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
    }
    
    .clause_content input {
        width: 338px;
        height: 44px;
        font-size: 14px;
        text-indent: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }
    /* 单价 */
    
    .unit_price {
        width: 100%;
        height: 50px;
        display: flex;
        margin-top: 30px;
    }
    
    .unit_price_content {
        width: 82%;
        margin-left: 2%;
        font-size: 20px;
        font-weight: bold;
        color: #f62959;
        display: flex;
        align-items: center;
    }
    /* 右边的内容 */
    
    .bottom_content_right img {
        width: 100%;
    }
    /* 合计 */
    
    .total_box {
        display: flex;
    }
    
    .total {
        width: 82%;
        margin-left: 9px;
        font-size: 20px;
        font-weight: bold;
        color: #f62959;
        display: flex;
        align-items: center;
    }
    /* 支付按钮 */
    
    .pay_box {
        width: 100%;
        display: flex;
        margin-top: 15px;
    }
    
    .pay_content {
        width: 90%;
    }
    /* 按钮 */
    
    .pay_content button {
        width: 120px;
        height: 50px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        border: 1px solid #f62959;
        background: none;
    }
    
    .pay_content button:nth-of-type(1) {
        background-color: #f62959;
        color: white;
        margin-left: 9px;
    }
    
    .pay_content button:nth-of-type(1):hover {
        background-color: #d6244e;
    }
    
    .pay_content button:nth-of-type(2) {
        background-color: white;
        color: #f62959;
        margin-left: 25px;
    }
    
    .pay_content button:nth-of-type(2):hover {
        color: #e7204e;
    }
    /* 温馨提示 */
    
    .hint_content {
        width: 100%;
        min-height: 246px;
        background: #fafafa;
        margin-top: 30px;
    }
    
    .hint_box {
        width: 96%;
        min-height: 206px;
        margin: auto;
        position: relative;
        top: 20px;
    }
    
    .hint_title p {
        font-size: 14px;
        color: #333333;
        line-height: 30px;
    }
    /* 内容 */
    
    .hint_text p {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
    }
    /* 有声录制 */
    
    .transcribe {
        width: 1129px;
        height: 389px;
        margin: auto;
        background: #f5f5f5;
        border-radius: 20px;
    }
    
    .transcribe_title {
        width: 100%;
    }
    
    .transcribe_title h1 {
        font-size: 30px;
        font-weight: normal;
        text-align: center;
        line-height: 110px;
    }
    /* 有声录制案例 */
    
    .transcribe_title-box {
        /* display: flex; */
        /* flex-wrap: wrap; */
        /* justify-content: space-around; */
        /* background-color: green; */
        margin-bottom: 2%;
        /* border: 1px red solid; */
    }
    /* 清除浮动 */
    
    .clearfix::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    
    .clearfix {
        *zoom: 1;
    }
    
    .voice {
        position: relative;
        float: left;
        margin: 10px 10px;
        width: 252px;
        height: 112px;
        background-color: white;
        border-radius: 3px;
    }
    
    .voice>.voice_a {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 30%;
        margin-top: 5%;
    }
    
    .voice_a-a {
        font-size: 18px;
        margin-left: 10px;
    }
    
    .voice_a-b {
        position: absolute;
        left: 180px;
    }
    
    .voice_a-c {
        position: absolute;
        left: 220px;
    }
    
    .voice>.voice_b {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30%;
        margin-top: 5%;
    }
    /* 音频 */
    
    .voice_b-a {
        width: 252px;
        height: 30px;
        outline: none;
    }
    
    .transcribe_bottom {
        width: 90%;
        margin: auto;
        margin-top: 30px;
    }
    /* 短视频制作 */
    
    .video_box {
        width: 1129px;
        /* height: 826px; */
        height: 760px;
        background: #f5f5f5;
        border-radius: 20px;
        margin: auto;
    }
    /* 标题 */
    
    .video_carousel_title {
        width: 100%;
        line-height: 110px;
    }
    
    .video_carousel_title h1 {
        font-size: 30px;
        font-weight: normal;
        text-align: center;
    }
    
    .video_bottom {
        width: 90%;
        margin: auto;
        margin-top: 30px;
    }
    /* 视频轮播 */
    
    .shiping {
        /* background-color: teal; */
        display: flex;
    }
    
    .btn {
        width: 50%;
        margin: 0 auto;
        margin-top: 40px;
        border-radius: 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgb(145, 145, 144);
        color: #ffffff;
        /* margin: 20px; */
    }
    
    .rise {
        text-align: center;
        margin-top: 20px;
        font-size: 22px;
        color: white;
        /* background-color: red; */
    }
    
    .being {
        color: #f62959;
        line-height: 143px;
        font-size: 22px;
        text-align: center;
        border-radius: 4px;
        background: #00000070;
    }
    
    .being img {
        vertical-align: middle;
    }
    
    .video {
        width: 75%;
        height: 480px;
        /* background-color: red; */
    }
    
    .video video {
        border-radius: 20px;
    }
    
    video:focus {
        outline: none;
    }
    
    .video-a {
        /* background-color: green; */
        width: 20%;
        margin-left: 5%;
    }
    
    .video-a li {
        display: inline-block;
        display: flex;
        height: 143px;
        margin-bottom: 20px;
        flex-direction: column;
        background-color: green;
        border-radius: 10px;
    }
    
    .video-b {
        background-color: #f5f5f5;
        width: 100%;
        /* height: 200px; */
    }
    
    .video-b_a {
        /* background-color: red; */
        font-size: 30px;
        color: #f62959;
        font-weight: 600;
        margin: 0 20px 40px 20px;
    }
    
    .video-b_b {
        /* background-color: pink; */
        margin-bottom: 20px;
        margin: 0 20px 0 20px;
        color: #333333;
    }
    
    .video-b_c {
        color: #333333;
        margin: 20px 20px 0 20px;
        /* background-color: green; */
    }
    /* ------------------- */
    
    .carousel_box {
        width: 94%;
        height: 450px;
        border: 1px red pink;
        margin: auto;
    }
    
    .containeraa {
        width: 802px;
        height: 452px;
        background: #ccc;
        border-radius: 10px;
    }
    
    .containerbb {
        width: 1068px;
        height: 200px;
        /* background: pink; */
        margin-top: 30px;
        border-radius: 10px;
    }
    
    .ui {
        width: 247px;
        height: 452px;
        display: flex;
        flex-direction: column;
        float: right;
        /* border: 1px solid blue; */
        list-style: none;
    }
    
    .ui li {
        height: 138px;
        border-radius: 10px;
    }
    
    .ui li:nth-child(1) {
        margin-bottom: 19px;
    }
    
    .ui li:nth-child(2) {
        margin-bottom: 19px;
    }
    /* 点击右侧播放按钮 */
    
    .beplaying {
        font-size: 23px;
        line-height: 138px;
        text-align: center;
        color: red;
    }
    /* 视频大小 */
    
    .customization-mp4 video {
        width: 802px;
        height: 452px;
        border-radius: 10px;
        background-color: black;
        outline: none;
        border: none;
        cursor: pointer;
    }
    /* 视频右侧按钮设置 */
    
    .handleaa {
        background-color: gray;
    }
    
    .handlebb {
        background-color: gray;
    }
    
    .handlecc {
        background-color: gray;
    }
    
    .handleaa-a {
        background-color: rgb(214, 205, 205);
        margin-top: 40px;
        margin-left: 30%;
        width: 91px;
        height: 33px;
        border-radius: 20px;
        font-size: 16px;
        color: #fcefef;
    }
    
    .handleaa-b {
        margin-top: 20px;
        margin-left: 20%;
        font-size: 23px;
        font-weight: 500;
        color: white;
    }
    /* 视频详情文字 */
    
    .one {
        font-size: 30px;
        font-weight: 600;
        color: rgb(246, 41, 89);
        padding-top: 10px;
    }
    
    .two {
        color: rgb(77, 77, 77);
        letter-spacing: 1px;
        font-weight: 600;
        margin-top: 30px;
    }
    
    .three {
        color: rgb(77, 77, 77);
        margin-top: 20px;
        letter-spacing: 1px;
        font-weight: 600;
    }
    /* 广告 */
    
    .advertising_boxinfo {
        width: 100%;
        min-width: 1200px;
        margin-top: 30px;
    }
    
    .advertisingstr {
        width: 1200px;
        margin: auto;
    }
    
    .aadvertisingstr img {
        width: 1200px;
        margin: auto;
    }
    /* 底部 */
    
    .o {
        margin-top: 40px;
    }
    
    .xuan {
        display: flex;
    }
    
    .cellphone_content_xuan {
        width: auto;
    }
    
    .el-input__inner {
        border: 1px solid #d9d9d9;
    }
</style>